import VueLazyload from 'vue-lazyload'
import Vue from 'vue'

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: '/img/cross.png',
  loading: '/img/preloader.svg',
  attempt: 1,
  lazyComponent: true
})
